import { PropsWithChildren } from "react";
import { HomeIcon } from "../../assets";
import styles from './Skill.module.scss';

interface SkillProps {
  price: number;
}
export function Skill({ children, price }: PropsWithChildren<SkillProps>) {
  return (
    <button className={styles.Root}>
      <span>
        <HomeIcon />
      </span>
      <span>
        {children}
      </span>
      <span>
        {price}€
      </span>
    </button>
  );
}
