import { useEffect, useRef } from "react";

// Tickrate is on millisecondes
export function Ticker(callback: () => void, tickrate: number) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, tickrate);
    return () => clearInterval(id);
  }, [tickrate]);
}
