import './App.css';
import { CurrencyBar } from './modules/CurrencyBar/CurrencyBar';
import { MainMenu } from './modules/MainMenu/MainMenu';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.App}>
      <CurrencyBar />
      <MainMenu />
    </div>
  );
}

export default App;
