import { HomeIcon } from "../../assets";
import { Skill } from "../../shared/Skill/Skill";
import styles from "./MainMenu.module.scss";

export function MainMenu() {
  return (
    <div className={styles.Root}>
      <div className={styles.RightMenu}>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
        <Skill price={250}>Auto Brew</Skill>
      </div>
      <div className={styles.AssetBloc}>
        <HomeIcon className={styles.CenterAsset} />
      </div>
      <div className={styles.LeftMenu}></div>
    </div>
  );
}
