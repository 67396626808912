import { useState } from "react";
import CountUp from "react-countup";
import { Ticker } from "../../utils/Ticker";
import styles from "./CurrencyBar.module.scss";

export function CurrencyBar() {
  // Euros ticks
  const [baseCurrency, setBaseCurrency] = useState<number>(500);
  // const [baseCurrencyTick, setBaseCurrencyTick] = useState<number>(1);

  // Beers ticks
  const [beerCurrency, setBeerCurrency] = useState<number>(500);
  // const [beerCurrencyTick, setBeerCurrencyTick] = useState<number>(1);

  Ticker(() => {
    let newBaseCurrency = baseCurrency + 1;
    setBaseCurrency(newBaseCurrency);

    let newBeerCurrency = beerCurrency + 1;
    setBeerCurrency(newBeerCurrency);
  }, 1000);

  return (
    <div className={styles.Root}>
      <div className={styles.List}>
        <span>100 ORGE</span>
        <span>100 BLE</span>
        <span>100 MALTE</span>
        <CountUp
          start={beerCurrency}
          end={beerCurrency + 1}
          suffix=" BEER"
        >
          {({ countUpRef }) => <span ref={countUpRef}>{beerCurrency}</span>}
        </CountUp>
        <CountUp
          start={baseCurrency}
          end={baseCurrency + 1}
          suffix=" €"
        >
          {({ countUpRef }) => <span ref={countUpRef}>{baseCurrency}</span>}
        </CountUp>
        {/* <button
          onClick={() => {
            setBaseCurrencyTick((baseCurrencyTick + 0.1));
          }}
        >
          Increase {baseCurrencyTick.toFixed(2)}€ /sec
        </button>

        <button
          onClick={() => {
            setBaseCurrency((baseCurrency - 200));
          }}
        >
          Remove
        </button> */}
      </div>
    </div>
  );
}
